<template>
  <div>
    <div class="flight-content">
      <div class="section dt-detail-info">
        <div class="row dt-detail-info__header">
          <div class="col-md-12 heading">Flight Details# {{ item.id }}</div>
        </div>
        <leg-list :item="item"></leg-list>
        <passengers :item="item"></passengers>
        <pricing :item="item"></pricing>
        <customer :item="item"></customer>
      </div>
      <actions :item="item"></actions>
    </div>
  </div>
</template>

<script>
import LegList from "./LegList.vue";
import Passengers from "./Passengers.vue";
import Pricing from "./Pricing.vue";
import Customer from "./Customer.vue";

import Actions from "./Actions.vue";

import { flightService } from "@/core/services";

export default {
  name: "FlightDetails",
  components: {
    LegList,
    Passengers,
    Pricing,
    Customer,
    Actions,
  },
  props: {
    item: {
      type: Object,
      default: function () {
        return {};
      },
    },
  },
  data() {
    return {};
  },
  computed: {},
  methods: {
    addNote(payload) {
      return flightService.saveNote(this.item.id, payload).then((res) => {
        this.item.comments = [...res.data.comments];
        return res;
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
