<template>
  <div>
    <base-header class="pb-6">
      <b-row align-v="center" class="py-4">
        <b-col lg="6" cols="7">
          <h6 class="h2 text-white d-inline-block mb-0 breadcrum-heading">
            {{ titleValue }}
          </h6>
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <route-bread-crumb>dghjgdsagjg</route-bread-crumb>
          </nav>
        </b-col>
      </b-row>
    </base-header>
    <b-container fluid class="mt--6">
      <b-card no-body class="mb-4">
        <b-card-body>
          <b-row>
            <b-col md="12">
              <data-table
                :table-columns="tableColumns"
                :table-data="immediateBookings"
                :border="true"
                row-class-name="row-expanded"
                :loading="loading"
              >
                <template #expandable="{ item }">
                  <flight-details :item="item"></flight-details>
                </template>
              </data-table>
            </b-col>
          </b-row>
        </b-card-body>
      </b-card>
    </b-container>
  </div>
</template>

<script>
import { RouteBreadCrumb } from "@/components";
import { immidiatetakeoffService } from "@/core/services";
import FlightDetails from "./components/Details.vue";
import { mapGetters } from "vuex";

export default {
  name: "Immidiatetakeoff",
  components: {
    RouteBreadCrumb,
    FlightDetails,
  },
  data() {
    return {
      tableColumns: [
        {
          prop: "booking_date",
          label: "Departure",
          component: "date-time",
          sortable: true,
          propPath: "is_live.departure_time",
          dateLocal: true,
          searchable: true,
        },
        {
          prop: "booking_details",
          label: "From",
          formatter: (row, _column, _cellValue) => {
            return row.booking_details && row.booking_details.length > 0
              ? row.booking_details[0].from.name
              : null;
          },
          searchable: true,
        },
        {
          prop: "booking_details",
          label: "To",
          formatter: (row, _column, _cellValue) => {
            return row.booking_details && row.booking_details.length > 0
              ? row.booking_details[0].to.name
              : null;
          },
          searchable: true,
        },
        {
          prop: "booking_details",
          label: "Arrival",
          component: "date-time",
          propPath: "is_live.arrival_time",
          dateLocal: true,
        },
        {
          prop: "booked_by",
          label: "Customer",
          formatter: (row, _column, _cellValue) => {
            return row.booked_by.full_name;
          },
          searchable: true,
        },
        {
          prop: "pilot",
          label: "Pilot",
          formatter: (row, _column, _cellValue) => {
            return row.booking_details && row.booking_details.length > 0
              ? row.booking_details[0].pilot
              : null;
          },
          searchable: true,
        },
      ],
      tableData: [],
      loading: false,

      titleValue: {
        type: String,
        default: "",
      },

      status: {
        type: String,
        default: "",
      },
    };
  },
  computed: {
    dtRef() {
      return this.$refs.dt;
    },
    ...mapGetters("immidiatetakeoff", ["immediateBookings"]),
  },
  watch: {
    "$route.query": {
      immediate: true,
      handler(param) {
        if (param.booking_id) {
          this.getAllFlight(
            param.booking_id,
            this.$route.meta.status,
            this.$route.meta.type ? this.$route.meta.type : ""
          );
        } else {
          this.getAllFlight(
            null,
            this.$route.meta.status,
            this.$route.meta.type ? this.$route.meta.type : ""
          );
        }

        if (this.$route.meta.status == "pending") {
          this.titleValue = "Immediate Take Off Flights";
          this.status = this.$route.meta.status;

          console.log(this.typeStatus, "2");
        } else if (this.$route.meta.status == "modified") {
          this.titleValue = "Modified Flights";
          this.status = this.$route.meta.status;
        } else {
          this.titleValue = "Closed Flights";
          this.status = this.$route.meta.status;
        }
      },
    },
  },
  mounted() {},
  methods: {
    getAllFlight(bookingID, status, typeStatus) {
      this.loading = true;

      immidiatetakeoffService
        .bookings(bookingID, status, typeStatus)
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep {
  .dt-detail-info {
    &__content-sec {
      &--booking {
        .flight-details {
          border-bottom: 1px solid #dbe4e5;
          padding-bottom: 1em;
          &:nth-last-of-type(1) {
            /* border-bottom: 0;
            padding-bottom: 0; */
          }
          .flight-datetime {
            &__date {
              margin-right: 0.75em;
            }
            &__time {
            }
          }
        }
      }
      &--passenger {
        .heading {
          margin-bottom: 0.8em;
          margin-top: 1em;
          font-size: 0.8rem;
          font-weight: 700;
        }
        .pass-detail {
          margin-bottom: 0.5em;
        }
      }
      &--pricing {
        .price-detail {
          margin-bottom: 0.8em;
          .col-md-3 {
            display: flex;
          }
          .badge {
            margin-right: 1em;
          }
          .time-label {
            font-weight: 500;
            width: 40%;
          }
          .time-value {
            font-weight: 500;
          }
        }
      }
      &--customer {
        .address {
          padding-bottom: 0.18em;
          .label {
            font-weight: 600;
            color: #807d7d;
            padding-right: 1em;
          }
        }
      }
    }
  }
}
</style>
