<template>
  <div>
    <div v-if="$route.meta.status != 'modified'" class="dt-detail-footer">
      <div v-if="$route.meta.status != 'completed'" class="action-list">
        <div class="action-list__item">
          <a
            href="javascript:void(0)"
            @click="changeStatus(BOOKING_STATUS.PILOT_CONFIRMED)"
          >
            Accept
          </a>
        </div>
        <div class="action-list__item">
          <a
            href="javascript:void(0)"
            @click="changeStatus(BOOKING_STATUS.PILOT_REJECTED)"
          >
            Reject
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  confirmDialogService,
  flightService,
  notificationService,
} from "@/core/services";
import { BOOKING_STATUS } from "@/core/enums";

export default {
  components: {},
  props: {
    item: {
      type: Object,
      default: function () {
        return {};
      },
    },
  },
  data() {
    return {
      BOOKING_STATUS,
    };
  },

  computed: {},
  methods: {
    changeStatus(status) {
      const msg = {
        [BOOKING_STATUS.PILOT_CONFIRMED]: `Are you want to accept the Flight# ${this.item.id}?`,
        [BOOKING_STATUS.PILOT_REJECTED]: `Are you want to reject the Flight# ${this.item.id}?`,
      };

      confirmDialogService.open(msg[status], () => {
        this.removeBooking();
        flightService
          .changeStatus(this.item.id, status)
          .then((res) => {
            notificationService.success(res.message);
            this.removeBooking();
          })
          .catch(() => {
            this.showLoading = false;
          });
      });
    },
    removeBooking() {
      this.$store.commit("immidiatetakeoff/removeImmediateBooking", {
        booking_id: this.item.id,
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
