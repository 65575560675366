<template>
  <div>
    <modal
      :show.sync="isShowModal"
      modal-classes="modal-secondary"
      @close="close()"
      @show="show()"
    >
      <h6 slot="header" class="modal-title text-uppercase">
        {{ titleValue }}
      </h6>
      <form v-if="!edite" class="new-event--form" @submit.prevent="saveLeg">
        <base-selectbox
          v-model="formGroup.user.value"
          :options="firebaseUser"
          placeholder="Select User"
          opt-label="full_name"
          opt-val="id"
          label="User"
          size="small"
        ></base-selectbox>
        <base-datepicker
          v-model="formGroup.date.value"
          :rules="formGroup.date.rule"
          :enable-hrs="true"
          :value="formGroup.date"
          label="Take-off Time"
          placeholder="Select Date"
        ></base-datepicker>

        <base-selectbox
          v-model="formGroup.from_location.value"
          :rules="formGroup.date.rule"
          :options="airportList"
          placeholder="Select Airport"
          opt-label="name"
          opt-val="id"
          label="From"
          size="small"
        ></base-selectbox>
        <base-selectbox
          v-model="formGroup.to_location.value"
          :rules="formGroup.date.rule"
          :options="airportList"
          opt-label="name"
          opt-val="id"
          placeholder="Select Airport"
          label="To"
          size="small"
        ></base-selectbox>
      </form>
      <form v-if="edite" class="new-event--form" @submit.prevent="saveLeg">
        <base-selectbox
          v-model="fullname"
          :options="firebaseUser"
          disabled
          placeholder="Select User"
          opt-label="full_name"
          opt-val="id"
          label="User"
          size="small"
        ></base-selectbox>
        <div class="editable-control">
          <i
            v-if="!editableControl.departureDate.enable"
            class="fas fa-pencil-alt text-primary cursor-pointer"
            @click="toggleControl('departureDate')"
          ></i>
          <i
            v-if="editableControl.departureDate.enable"
            class="fas fa-times text-danger cursor-pointer multi"
            @click="toggleControl('departureDate')"
          ></i>
          <i
            v-if="editableControl.departureDate.enable"
            class="fas fa-save text-success cursor-pointer multi"
            @click="onChangeDeparture()"
          ></i>
          <base-datepicker
            ref="datepicker"
            v-model="formGroup.date.value"
            :value="formGroup.date.value"
            :readonly="
              item.is_consecutive || !editableControl.departureDate.enable
                ? true
                : false
            "
            :rules="formGroup.date.rule"
            :enable-hrs="true"
            :cast="false"
            label="Take-off Time"
            placeholder="Select Date"
          ></base-datepicker>
        </div>
        <base-selectbox
          v-model="formGroup.from_location.value"
          :rules="formGroup.date.rule"
          :options="airportList"
          placeholder="Select Airport"
          opt-label="name"
          disabled
          opt-val="id"
          label="From"
          size="small"
        ></base-selectbox>
        <div class="editable-control">
          <i
            v-if="!editableControl.arrivalAirport.enable"
            class="fas fa-pencil-alt text-primary cursor-pointer"
            @click="toggleControl('arrivalAirport')"
          ></i>
          <i
            v-if="editableControl.arrivalAirport.enable"
            class="fas fa-times text-danger cursor-pointer multi"
            @click="toggleControl('arrivalAirport')"
          ></i>
          <i
            v-if="editableControl.arrivalAirport.enable"
            class="fas fa-save text-success cursor-pointer multi"
            @click="onChangeDestination()"
          ></i>

          <base-selectbox
            v-model="formGroup.to_location.value"
            :rules="formGroup.date.rule"
            :options="airportList"
            :disabled="!editableControl.arrivalAirport.enable"
            opt-label="name"
            opt-val="id"
            placeholder="Select Airport"
            label="To"
          ></base-selectbox>
        </div>
      </form>
      <progress-loader :show="loading" :no-wrap="true"></progress-loader>

      <template v-slot:footer>
        <button
          v-if="!edite"
          type="submit"
          class="btn btn-primary new-event--add"
          :disabled="loading"
          @click="saveLeg"
        >
          Submit
        </button>

        <base-button
          class="ml-auto"
          type="primary"
          :outline="true"
          @click="close()"
        >
          Close
        </base-button>
      </template>
    </modal>
  </div>
</template>

<script>
import { FormGroup } from "@/core/models";
import { membersService } from "@/core/services";
import { mapGetters } from "vuex";
import { flightService, notificationService } from "@/core/services";
import { DateTime } from "@/core/utils";

export default {
  props: {
    showLegModalForm: {
      type: Boolean,
      default: false,
    },
    item: {
      type: Object,
      default: function () {
        return {};
      },
    },
    customer: {
      type: Object,
      default: function () {
        return {};
      },
    },
    edite: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      model: {},
      modalState: false,
      showLoading: false,
      formGroup: new FormGroup({
        from_location: [null, { required: true }],
        to_location: [null, { required: true }],
        date: [null, { required: true }],
        user: [null, { required: true }],
      }),
      firebaseUser: {
        type: Array,
        default: () => [],
      },
      titleValue: {
        type: String,
        default: "",
      },
      source: {
        airports: [
          { label: "Tallinn Airport", value: "EECL" },
          { label: "City Hall", value: "EECP" },
          { label: "Antsla Airfield", value: "EETN" },
        ],
      },
      loading: false,
      editableControl: {
        departureDate: {
          enable: false,
          field: "date",
        },
        arrivalAirport: {
          enable: false,
          field: "to_location",
        },
      },
    };
  },
  computed: {
    fullname: {
      get() {
        return `${this.customer.full_name} ( ${this.customer.phone} )`;
      },
      set(newValue) {
        const m = newValue.match(/(\S*)\s+(.*)/);

        this.customer.full_name = m[1];
        this.customer.phone = m[2];
      },
    },
    isShowModal: {
      get() {
        return this.showLegModalForm;
      },
      set(value) {
        this.modalState = value;
      },
    },

    ...mapGetters("airport", ["airportList"]),
  },
  mounted() {
    this.getFirebaseUser();
  },

  methods: {
    getFirebaseUser() {
      membersService.firebaseusers().then((res) => {
        this.firebaseUser = res.data;
      });
    },

    onChangeDeparture() {
      this.showLoading = true;

      flightService
        .changeDeparture(this.item.booking_id, this.formGroup.date.value)
        .then((res) => {
          notificationService.success(res.message);
          this.showLoading = false;
        })
        .catch(() => {
          this.showLoading = false;
        });
    },

    onChangeDestination() {
      this.showLoading = true;

      flightService
        .changeDestination(
          this.item.booking_id,
          this.formGroup.to_location.value
        )
        .then((res) => {
          notificationService.success(res.message);
          this.showLoading = false;
        })
        .catch(() => {
          this.showLoading = false;
        });
    },

    onChangeTo(event) {
      console.log("on  to", event);
    },

    saveLeg() {
      this.loading = true;
      const payload = {
        ...this.formGroup.value,
        date: DateTime.utc(this.formGroup.value.date),
      };

      flightService
        .save_ferry(payload)
        .then((res) => {
          notificationService.success(res.message);
          this.close();
        })
        .finally(() => (this.loading = false));
    },

    show() {
      if (this.item.id) {
        this.titleValue = "Edit Leg";
        const formGroup = {
          from_location: this.item.from.name,
          to_location: this.item.to.name,
          date: this.item.departure_time,
        };
        this.formGroup.populate(formGroup);
      } else {
        this.titleValue = "Add Leg";
      }
    },
    close() {
      this.isShowModal = false;
      this.$emit("close");
      this.formGroup.reset();
    },

    toggleControl(field) {
      this.editableControl[field].enable = !this.editableControl[field].enable;
    },
  },
};
</script>

<style lang="scss" scoped>
.editable-control {
  display: flex;
  flex-direction: column;
  position: relative;
  i {
    position: absolute;
    right: 1em;
    &.multi {
      &:nth-last-of-type(1) {
        margin-right: 1.3em;
      }
    }
  }
}
</style>
