<template>
  <div>
    <div
      class="dt-detail-info__content-sec dt-detail-info__content-sec--booking"
    >
      <div
        v-for="booking_detail in item.booking_details"
        :key="booking_detail.id"
        class="flight-details pt-3"
      >
        <div class="row">
          <div class="col-md-3 flight-datetime">
            <span class="flight-datetime__date">
              {{ bookingDate(booking_detail.departure_time) }}
            </span>
            <span class="flight-datetime__time">
              {{ bookingTime(booking_detail.departure_time) }}
            </span>
          </div>
          <div class="col-md-3 flight-lineadjust">
            {{ booking_detail.from.code }}
            <small>({{ booking_detail.from.name }})</small>
            - {{ booking_detail.to.code }}
            <small>({{ booking_detail.to.name }})</small>
          </div>
          <div class="col-md-3 text-center">
            {{ bookingTime(booking_detail.arrival_time) }}
            <badge
              v-if="booking_detail.is_live"
              custom-class="badge-md badge-flight"
              type="fl-leg-live"
              :rounded="true"
              size="md"
            >
              Live
            </badge>
            <badge
              v-if="!booking_detail.is_live"
              custom-class="badge-md badge-flight"
              type="fl-leg-ferry"
              :rounded="true"
              size="md"
            >
              Ferry
            </badge>
          </div>
          <div class="col-md-2">
            <div class="col-md-1 ml-1">Pilot: {{ booking_detail.pilot }}</div>
          </div>
          <template>
            <div v-if="$route.meta.status == 'modified'" class="col-md-1">
              <i
                v-permission="[GRANT.EDIT_LEG]"
                class="fas fa-pencil-alt text-primary cursor-pointer"
                @click="
                  toggleLegForm(true, booking_detail, true, item.booked_by)
                "
              ></i>
            </div>
          </template>
        </div>
      </div>
    </div>
    <!-- <div class="flight-details pt-3">
      <base-button size="sm" type="primary" @click="toggleLegForm(true)">
        <span class="fas fa-plus"></span>
        Add Leg
      </base-button>
    </div> -->

    <leg-form
      v-if="$route.meta.status == 'modified'"
      :show-leg-modal-form="showLegModalForm"
      :item="itemData"
      :customer="customerData"
      :edite="editeOption"
      @close="toggleLegForm(false)"
    ></leg-form>
  </div>
</template>

<script>
import moment from "moment";
import LegForm from "./../../flights/components/LegForm.vue";
import { DateTime } from "@/core/utils";
import { GRANT } from "@/core/enums";

export default {
  components: {
    LegForm,
  },
  filters: {
    date: function (date) {
      return moment(date).format("Y-MM-DD");
    },
    time: function (date) {
      return moment(date).format("HH:mm");
    },
  },
  props: {
    item: {
      type: Object,
      default: function () {
        return {};
      },
    },
  },
  data() {
    return {
      showLegModalForm: false,
      flight: {
        checkin: {
          booked: true,
          weather: true,
          flightplan: true,
        },
        checkout: {
          booked: true,
          weather: true,
          flightplan: true,
        },
      },
      editeOption: false,
      itemData: {
        type: Object,
      },
      customerData: {
        type: Object,
      },
      GRANT: GRANT,
    };
  },
  computed: {
    bookingDate() {
      return (val) => {
        return DateTime.parseUtc(val, "YYYY-MM-DD");
      };
    },
    bookingTime() {
      return (val) => {
        return DateTime.parseUtc(val, "HH:mm", "YYYY-MM-DD HH:mm:ss");
      };
    },
  },
  methods: {
    toggleLegForm(val, item, editoption, customerdata) {
      this.showLegModalForm = val;
      this.itemData = item
        ? {
            ...item,
            departure_time: DateTime.parseUtc(item.departure_time),
            arrival_time: DateTime.parseUtc(item.arrival_time),
          }
        : {};
      this.editeOption = editoption;
      this.customerData = customerdata;
    },
  },
};
</script>

<style lang="scss" scoped>
.flight-lineadjust {
  white-space: normal;
}
</style>
