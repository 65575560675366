<template>
  <div>
    <div
      v-if="item.passengers.length"
      class="dt-detail-info__content-sec dt-detail-info__content-sec--passenger"
    >
      <div class="heading">
        <span>Passenger list</span>
      </div>

      <div
        v-for="passenger in item.passengers"
        :key="passenger.id"
        class="row pass-detail"
      >
        <div class="col-md-2">{{ passenger.name }}</div>
        <div class="col-md-4">{{ passenger.date_of_birth }}</div>
        <div class="col-md-4">{{ passenger.country }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    item: {
      type: Object,
      default: function () {
        return {};
      },
    },
  },
  data() {
    return {};
  },
  computed: {},
  methods: {},
};
</script>

<style lang="scss" scoped></style>
